import router from "@/router"
import Footer from "@/views/Footer/index.vue"
import Header from "@/views/Header/index.vue"
import MobileFooter from "@/views/Mobile/MobileFooter/index.vue"
import MobileHeader from "@/views/Mobile/MobileHeader/index.vue"
import ElementPlus from "element-plus"
import zhCn from "element-plus/dist/locale/zh-cn.mjs"
import { createApp } from "vue"
import App from "./App.vue"
const app = createApp(App)
app.component("Header", Header)
app.component("Footer", Footer)
app.component("MobileHeader", MobileHeader)
app.component("MobileFooter", MobileFooter)
app.use(router)
app.use(ElementPlus, {
  locale: zhCn,
})
app.mount("#app")

