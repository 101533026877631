import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
// import HomeView from '@/views/HomeView.vue'
const width = window.innerWidth
const HomeView = () => {
  console.log(width, 1.01)
  if (width > 1024) {
    return import("@/views/Home/index.vue")
  } else {
    // return import("@/views/Mobile/MobileHome/index.vue")
     return import("@/views/Home/index.vue")
  }
} 

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/CoreBusiness",
    name: "CoreBusiness",
    component: () => import("@/views/CoreBusiness/index.vue"),
  },
  {
    path: "/IndustrialSolutions",
    name: "IndustrialSolutions",
    component: () => import("@/views/IndustrialSolutions/index.vue"),
  },
  {
    path: "/AboutCompany",
    name: "AboutCompany",
    component: () => import("@/views/AboutCompany/index.vue"),
  },
    {
    path: "/CompanyInformation",
    name: "CompanyInformation",
    component: () => import("@/views/CompanyInformation/index.vue"),
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: () => import("@/views/ContactUs/index.vue"),
  },
  {
    path: "/CaseProject1",
    name: "CaseProject1",
    component: () => import("@/views/IndustrialSolutions/CaseProject1.vue"),
  },
  {
    path: "/CaseProject2",
    name: "CaseProject2",
    component: () => import("@/views/IndustrialSolutions/CaseProject2.vue"),
  },
  {
    path: "/MobileCoreBusiness",
    name: "MobileCoreBusiness",
    component: () => import("@/views/Mobile/MobileCoreBusiness/index.vue"),
  },
  {
    path: "/MobileIndustrialSolutions",
    name: "MobileIndustrialSolutions",
    component: () => import("@/views/Mobile/MobileIndustrialSolutions/index.vue"),
  },
  {
    path: "/MobileAboutCompany",
    name: "MobileAboutCompany",
    component: () => import("@/views/Mobile/MobileAboutCompany/index.vue"),
  },
  {
    path: "/MobileContactUs",
    name: "MobileContactUs",
    component: () => import("@/views/Mobile/MobileContactUs/index.vue"),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})


const url = window.document.location.href;
// 添加百度统计
if (url.includes(".com")) {
  var _hmt: any = _hmt || [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
  window._hmt = _hmt; // 将_hmt挂载到window下
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?dfd37e7a0b0e06029cdd10ae22d82d02";
    var s = document.getElementsByTagName("script")[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    s.parentNode.insertBefore(hm, s);
    
  })();
}

router.beforeEach((to, from, next) => {
  // 百度统计
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const _hmt = window._hmt;
  if (_hmt) {
    if (to.path) {
      _hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});



export default router
